import { Directive, ElementRef, HostListener } from '@angular/core';
import { UtilsService } from '../services/utils-service/utils.service';
@Directive({
    selector: 'input[numbersOnly]',
})
export class NumberDirective {
    private regex = new RegExp(/^-?[0-9\/]+(\[0-9\/'']*){0,1}$/g);
    private specialKeys: Array<string> = [
        'Backspace',
        'Tab',
        'End',
        'Home',
        'ArrowLeft',
        'ArrowRight',
    ];

    constructor(private el: ElementRef,public utils:UtilsService) {}
    
    @HostListener('paste', ['$event'])
    @HostListener('keydown', ['$event'])
    handleInput(event: Event) {
        const inputType = event.type; // Determine the type of event
        const inputElement = this.el.nativeElement; // Get the input element reference
        console.log('Event Type:', inputType);
        // let keyName:any = null;

        if (inputType === 'keydown') {
            const keyboardEvent = event as KeyboardEvent;
            if (this.specialKeys.includes(keyboardEvent.key)) {
                return;
            }

            if(this.utils.keyName && (this.utils.keyName=='Control' && keyboardEvent.key=='v')){
                this.pasteValueFunction(event, inputElement);
                return;
            }
            this.utils.keyName = keyboardEvent.key
        
            const current: string = inputElement.value;
            // Predict the next value if the key is pressed (appended)
            const next: string = current + keyboardEvent.key;

            if (!next.match(this.regex)) {
                keyboardEvent.preventDefault(); 
            }
        } else if (inputType === 'paste') {
            this.pasteValueFunction(event,inputElement)
        }
    }

    pasteValueFunction(event:any, inputElement:any){
        const clipboardEvent = event as ClipboardEvent;
        const clipboardData = clipboardEvent.clipboardData || (window as any).clipboardData;
 
        if (!clipboardData) {
            console.warn('ClipboardData is not available');
            return;
        }
 
        const pastedText = clipboardData.getData('text');
        const current: string = inputElement.value;
        const next: string = current + pastedText;
        if (!next.match(this.regex)) {
            clipboardEvent.preventDefault(); 
        }
    }
}
