import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../storage-service/local-storage.service';
import { HttpErrorHandlerService } from '../../http-error-handler/http-error-handler.service';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { constant } from '../../global/constants';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    hostUrl = environment.url;
    userhostUrl = environment.userUrl;
    lastCode: any;
    setFileSize: any;
    private headerParams: { [key: string]: any } = {};
    public resetClientList = new BehaviorSubject<Object>({});
    isShowLoader = false;

    constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private httpErrorHandler: HttpErrorHandlerService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: Document
    ) {}

    getHeader(headerOptions: any, doNotSendAuthorizationParam: any) {
        this.headerParams = { authorization: '' };
        if (this.localStorageService.getLocalStore('language')) {
            this.headerParams['X-L10N-Locale'] =
        this.localStorageService.getLocalStore('language');
        } else {
            this.headerParams['X-L10N-Locale'] = 'en';
        }
        if (this.localStorageService.getLocalStore('currentCompany')) {
            this.headerParams['x-auth-organization'] =
        +this.localStorageService.getLocalStore('currentCompany');
        }
        if (
            doNotSendAuthorizationParam !== true &&
      this.localStorageService.getLocalStore('token')
        ) {
            this.headerParams['authorization'] =
        this.localStorageService.getLocalStore('token');
        }
        if (
            this.localStorageService.getLocalStore('currentCompany') &&
      this.localStorageService.getLocalStore('currentCompany') !== '0'
        ) {
            this.headerParams['x-auth-organization'] =
        this.localStorageService.getLocalStore('currentCompany');
        }
        if (headerOptions.companies && headerOptions.companies !== '0') {
            this.headerParams['x-auth-organization'] = headerOptions.companies;
            delete headerOptions['companies'];
        } else {
            delete headerOptions['companies'];
        }
        if (headerOptions) {
            Object.assign(this.headerParams, headerOptions);
        }

        let headers = new HttpHeaders(this.headerParams);
        headers = headers
            .set('Access-Control-Allow-Origin', '*')
            .set('Content-Type', 'application/json')
            .set('Access-Control-Expose-Headers', 'Content-Length');
        return { headers };
    }
    post(url: string, body: any, stopAutoErrorHandling?: any) {
        return new Promise((resolve, _reject) => {
            this.http
                .post(`${url}`, body)
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.lastCode = '';
                        if (!stopAutoErrorHandling) {
                            this.httpErrorHandler.handleError(err);
                            resolve(400);
                        } else {
                            resolve(400);
                        }
                    },
                });
        });
    }

    get(url: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${url}`)
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.httpErrorHandler.handleError(err);
                        reject(err);
                    },
                });
        });
    }

    put(url: string, body: any) {
        return new Promise((resolve, _reject) => {
            this.http
                .put(`${url}`, body)
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.httpErrorHandler.handleError(err);
                        resolve(400);
                    },
                });
        });
    }

    patch(url: string, body: any) {
        return new Promise((resolve, _reject) => {
            this.http
                .patch(`${url}`, body)
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.httpErrorHandler.handleError(err);
                        resolve(400);
                    },
                });
        });
    }
    delete(url: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(url)
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.httpErrorHandler.handleError(err);
                        reject(err);
                    },
                });
        });
    }

    deleteWithForm(url: string, body: any) {
        const Options = {
            headers: new HttpHeaders(this.headerParams),
            body: body,
        };
        return new Promise((resolve, reject) => {
            this.http
                .delete(url, Options)
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.httpErrorHandler.handleError(err);
                        reject(err);
                    },
                });
        });
    }

    postFormData(url: string, body: any, stopAutoErrorHandling?: any) {
        return this.http
            .post(`${url}`, body, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                catchError((error: any) => {
                    return this.httpErrorHandler.handleError(error);
                })
            );
    }

    formData(url: string, body: any) {
        return new Promise((resolve, reject) => {
            this.http.post(`${url}`, body).subscribe((response: any) => {
                resolve(response);
            });
        });
    }

    showSuccess(message: string) {
        this.toastr.success(message);
    }

    showError(message: string) {
        this.toastr.error(message);
    }

    showInfo(message: string) {
        this.toastr.info(message);
    }

    showLoading() {
        this.spinner.show();
        this.document.body.classList.add('preventEvents');
    }

    hideLoading() {
        this.spinner.hide();
        this.document.body.classList.remove('preventEvents');
    }

    showLoadingBgTransparent() {
        this.document.body.classList.add('disable-screen-loader');
    }

    hideLoadingBgTransparent() {
        this.document.body.classList.remove('disable-screen-loader');
    }
    updateProfilePic(event: any): Promise<any> {
        return new Promise((resolve) => {
            if (event.target.files[0].size < this.setFileSize) {
                if (
                    event.target.files[0].type === 'image/png' ||
          event.target.files[0].type === 'image/jpg' ||
          event.target.files[0].type === 'image/jpeg'
                ) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const fileData = {
                            result: reader.result,
                            file: event.target.files[0],
                        };
                        resolve(fileData);
                    };
                    reader.readAsDataURL(event.target.files[0]);
                } else {
                    resolve(false);
                    return this.showError('Please select .png/.jpg/.jpeg file only.');
                }
            } else {
                resolve(false);
                return this.showError(
                    'Please select small image file Max size is ' + this.setFileSize
                );
            }
        });
    }

    uploadFileWithProgress(path: any, obj: any) {
        return new Promise((resolve, reject) => {
            const networkIsConnected = this.getNetworkConnection();
            if (networkIsConnected) {
                const headers = this.getHeader(false, true);
                this.http
                    .post(`${this.hostUrl}${path}`, obj, headers)
                    .pipe(
                        map((res) => {
                            return res;
                        })
                    )
                    .subscribe({
                        next: (res) => {
                            resolve(res);
                        },
                        error: (err) => {
                            this.httpErrorHandler.handleError(err);
                            reject(err);
                        },
                    });
            } else {
                this.toastr.error(constant.NO_INTERNET_CONNECTION_MSG);
            }
        });
    }

    getNetworkConnection() {
        const isOnline: boolean = navigator.onLine;
        if (isOnline) {
            return true;
        }
        return false;
    }

    getForBlob(url: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${url}`, { responseType: 'blob' })
                .pipe(
                    map((res: any) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.httpErrorHandler.handleError(err);
                        reject(err);
                    },
                });
        });
    }
    postError(url: string, body: any, stopAutoErrorHandling?: any) {
        return new Promise((resolve: any, _reject) => {
            this.http
                .post(`${url}`, body)
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
                .subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.lastCode = '';
                        if (!stopAutoErrorHandling) {
                            this.httpErrorHandler.handleError(err);
                            resolve(err);
                        } else {
                            resolve(err);
                        }
                    },
                });
        });
    }
}
