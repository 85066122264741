import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';

@Injectable({
    providedIn: 'root',
})
export class TimesheetSettingService {
    res: any;
    apiEndPoint = NAVIGATE_ROUTES;
    companyId: any;
    public userDetail: UserDetailsService;
    timesheetChange = new BehaviorSubject<any>('');

    constructor(private injector: Injector, private apiService: ApiService) {
        this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
    }

    async timesheetChangeSearch(data: any) {
        this.timesheetChange.next(data);
    }

    async timesheetSetting(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TIMESHEET_SETTING_BFF}/config`;
        await this.apiService.post(url, data).then((resData) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getTimesheetSetting(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TIMESHEET_SETTING_BFF}/config?company_id=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getTimesheetMasterSetting(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TIMESHEET_SETTING_BFF}/master-setup`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getMemberDetailInConfig(data: any): Promise<Observable<any>> {
        this.companyId = await this.userDetail.getCompanyId();
        const url = `${this.apiEndPoint.TIMESHEET_SETTING}/getUserList?company_id=${this.companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async timesheetWeek(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TIMESHEET_SETTING_BFF}/weekconfig`;
        await this.apiService.post(url, data).then((resData) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async timesheetDay(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TIMESHEET_SETTING_BFF}/dayupdate`;
        await this.apiService.put(url, data).then((resData) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getAttendanceTimesheetSetting(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.ATTENDANCE_TIMESHEET_SETTING_BFF}?companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async attendanceTimesheetWeek(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.ATTENDANCE_WEEK_CONFIG}`;
        await this.apiService.post(url, data).then((resData) => {
            this.res = resData;
        });
        return of(this.res);
    }
}
