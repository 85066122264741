import {
    Component,
    Injector,
    OnInit
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SIDEBAR_MENU } from 'src/app/shared/global/constants';
import { AuthService } from 'src/app/shared/services/auth-service/auth.service';
import { ManageOrganizationService } from 'src/app/shared/services/organization/manage-organization.service';
import { LocalStorageService } from 'src/app/shared/services/storage-service/local-storage.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import * as CryptoJS from 'crypto-js';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Observable, take, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { DiscussionSocketService } from 'src/app/shared/services/discussion/discussion-socket.service';
import { TimesheetSettingService } from 'src/app/shared/services/organization-settings/timesheet-setting.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { SaveReportService } from 'src/app/shared/services/report-service/save-report.service';
import { taskState } from 'src/app/shared/ngrx/action/task-state.actions';
import * as _ from 'lodash';
import { taskState as TaskState } from 'src/app/shared/ngrx/reducer/task-state.reducer';
import { EmailManagementService } from 'src/app/shared/services/email-management/email-management.service';
import { PersonalizedUserEmit } from 'src/app/shared/services/personalized-user-service/personalized-user-emit.service';
import { AlertService } from 'src/app/shared/services/alert-service/alert.service';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
    members = 30;
    selected: any;
    selectedData!: string;
    matches: any;
    avatar!: string;
    userId: any;
    selectedCompanyId: any;
    companyList: any = [];
    sideMenu: any = [];
    public currentPage = '';
    public activeMenu: any = '';
    public organization: ManageOrganizationService;
    public userDetail: UserDetailsService;
    selectedCompany: any;
    profile_image: any;
    imgFolderPathCommon = 'assets/images';
    res: any;
    users: Observable<any>;
    subscriptionMSG: Subscription;
    timeLogData: any;
    subscription: Subscription;
    memberCount: any;
    companyCount: any;
    public emitPersonalizedUser: PersonalizedUserEmit;
    constructor(
        private formBuilder: FormBuilder,
        private store: Store<{ taskState: TaskState }>,
        private router: Router,
        public timeSheetSettingService: TimesheetSettingService,
        public authService: AuthService,
        // private socketService: SocketService,
        private localStorage: LocalStorageService,
        private socket: SocketService,
        private injector: Injector,
        private utils: UtilsService,
        private discussionSocketService: DiscussionSocketService,
        private notificationService: NotificationService,
        public reportService: SaveReportService,
        public emailService: EmailManagementService,
        public alertservice: AlertService
    ) {
        this.emitPersonalizedUser =
            injector.get<PersonalizedUserEmit>(PersonalizedUserEmit);
        this.sideMenu = SIDEBAR_MENU;
        this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
        this.organization = injector.get<ManageOrganizationService>(
            ManageOrganizationService
        );
        this.userId = this.userDetail.userId;
        this.socket.initSocket();
        // when company add then emit this
        this.subscription = this.userDetail.updateCompanyList.subscribe(
            async (res: any) => {
                if (res) {
                    await this.getCompanyList();
                    this.ngOnInit();
                    // location.reload();
                }
            }
        );
        this.subscription = this.emitPersonalizedUser.companySwitch$.subscribe(
            async (data: any) => {
                if(data){
                    const cmpid = parseInt(
                        CryptoJS.AES.decrypt(data?.trim(), '').toString(CryptoJS.enc.Utf8)
                    );
                    await this.companyswitch(cmpid);
                }
            }
        );
    }

    async ngOnInit() {
        this.discussionSocketService.reconnect();
        this.updateStoreData();
        await this.getCompanyList();
        await this.changeComapny();
        this.getCompanyData();
    }
    async getCompanyData() {
        this.selectedCompanyId = await this.localStorage.getDataFromIndexedDB(
            'selectedCompanyId'
        );
        const authorization = await this.localStorage.getLocalStore(
            'authorization'
        );
        const token = await this.localStorage.decodeJWT(authorization);
        const selectedCompany = token?.company_id;
        const companyDetails = this.companyList.find(
            (item: any) => item?.id == selectedCompany
        );
        this.selectedCompanyId = companyDetails?.id;
        this.profile_image = companyDetails?.profile_image;
        const user_company_relations = await this.userDetail.getUserRolePermission(
            this.userId,
            this.selectedCompanyId
        );
        if (user_company_relations) {
            user_company_relations.member_id = user_company_relations?.memberId;
        }
        delete user_company_relations?.memberId;

        this.discussionSocketService.reconnect();

        this.authService
            .updateToken({ company_id: companyDetails?.id, user_id: this.userId })
            .subscribe(async (response: any) => {
                this.localStorage.setLocalStore('authorization', response.jwtToken);
                this.selectedCompanyId = companyDetails?.id;
                this.profile_image = companyDetails?.profile_image;
                this.selectedCompanyId = CryptoJS.AES.encrypt(
                    this.selectedCompanyId?.toString().trim(),
                    ''
                ).toString();
                this.selectedCompany = companyDetails.company_name;
                const selectedCompanyData = await companyDetails;
                if (this.companyCount == 1 && companyDetails == undefined) {
                    this.authService
                        .updateToken({
                            company_id: this.companyList[0]?.id,
                            user_id: this.userId,
                        })
                        .subscribe(async (response: any) => {
                            if (response) {
                                await this.localStorage.setLocalStore(
                                    'authorization',
                                    response.jwtToken
                                );
                                const authorization = await this.localStorage.getLocalStore(
                                    'authorization'
                                );
                                const token = await this.localStorage.decodeJWT(authorization);
                                const selectedCompany = token?.company_id;
                                const companyDetails = this.companyList.find(
                                    (item: any) => item?.id == selectedCompany
                                );
                                this.selectedCompanyId = companyDetails?.id;
                                const user_company_relations =
                                    await this.userDetail.getUserRolePermission(
                                        this.userId,
                                        this.selectedCompanyId
                                    );
                                if (user_company_relations) {
                                    user_company_relations.member_id =
                                        user_company_relations?.memberId;
                                }
                                delete user_company_relations?.memberId;
                                this.selectedCompany = selectedCompanyData?.company_name;
                                this.avatar = await this.utils.forShortName(
                                    this.selectedCompany
                                );
                                debugger;
                                await this.localStorage.setDataInIndexedDB(
                                    'selectedCompanyId',
                                    this.selectedCompanyId
                                );
                                // await this.localStorage.setDataInIndexedDB(
                                //   "companyId",
                                //   this.selectedCompanyId
                                // );
                                await this.localStorage.setDataInIndexedDB(
                                    'selectedCompanyName',
                                    selectedCompanyData?.company_name
                                );
                                await this.localStorage.setDataInIndexedDB(
                                    'selectedCompanyData',
                                    selectedCompanyData
                                );
                                location.reload();
                            }
                        });
                }
                this.selectedCompany = selectedCompanyData?.company_name;
                this.avatar = await this.utils.forShortName(this.selectedCompany);
                debugger;
                await this.localStorage.setDataInIndexedDB(
                    'selectedCompanyId',
                    this.selectedCompanyId
                );
                // await this.localStorage.setDataInIndexedDB(
                //   "companyId",
                //   this.selectedCompanyId
                // );
                await this.localStorage.setDataInIndexedDB(
                    'selectedCompanyName',
                    selectedCompanyData?.company_name
                );
                await this.localStorage.setDataInIndexedDB(
                    'selectedCompanyData',
                    selectedCompanyData
                );
            });
        // }
        this.getTimeSheetDetail(selectedCompany);
    }
    async selectedValue(event: any, fromEmail?: any) {
        this.selectedData = event;
        this.avatar = await this.utils.forShortName(this.selectedData);
        const companyDetails = this.companyList.find(
            (item: any) => event === item.company_name
        );
        this.selectedCompanyId = companyDetails.id;
        const user_company_relations = await this.userDetail.getUserRolePermission(
            this.userId,
            this.selectedCompanyId
        );
        user_company_relations.member_id = user_company_relations.memberId;
        delete user_company_relations.memberId;
        this.authService
            .updateToken({ company_id: companyDetails.id, user_id: this.userId })
            .subscribe(async (response: any) => {
                this.localStorage.setLocalStore('authorization', response.jwtToken);
                this.selectedCompanyId = companyDetails.id;
                this.profile_image = companyDetails.profile_image;
                this.selectedCompanyId = CryptoJS.AES.encrypt(
                    this.selectedCompanyId?.toString().trim(),
                    ''
                ).toString();
                this.selectedCompany = companyDetails.company_name;
                const selectedCompanyData = await companyDetails;
                await this.localStorage.setDataInIndexedDB(
                    'selectedCompanyId',
                    this.selectedCompanyId
                );
                // await this.localStorage.setDataInIndexedDB(
                //   "companyId",
                //   this.selectedCompanyId
                // );
                await this.localStorage.setDataInIndexedDB(
                    'selectedCompanyName',
                    this.selectedCompany
                );
                await this.localStorage.setDataInIndexedDB(
                    'selectedCompanyData',
                    selectedCompanyData
                );
                if (!fromEmail) {
                    this.router.navigate(['/dashboard']).then(() => {
                        // this.router.navigate(["/dashboard"])
                        location.reload();
                    });
                }
            });
    }

    async changeRoute(e: any) {
        this.localStorage.clearStorageFor('filter');
        this.localStorage.clearStorageFor('sort');
        this.localStorage.clearStorageFor('status');
        this.localStorage.clearStorageFor('filterValue');
        this.localStorage.clearStorageFor('date');
        this.localStorage.clearStorageFor('user-profile-details');
        const event = e;
        if (!event) {
            this.notificationService.setNotification(true);
        }
        let upgradeState: any = await this.localStorage.getDataFromIndexedDB("upgradeState")
        if (upgradeState == true) {
            this.updateStoreData()
            this.localStorage.setDataInIndexedDB("upgradeState", false)
        }
        if (this.localStorage.getData('navClosed')) {
            this.localStorage.clearStorageFor('navClosed');
            this.localStorage.clearStorageFor('status');
            this.localStorage.clearStorageFor('user_sort_value');
        }
    }

    public activeClass(currentRoute: any) {
        if (this.currentPage.includes(currentRoute) && currentRoute !== '') {
            return 'active-link';
        } else {
            return '';
        }
    }

    getPath() {
        this.currentPage = this.router.url;
        if (!this.currentPage.includes('/project/detail')) {
            // this.localStorage.removeDataFromIndexedDB('selectedProject');
        }
        return this.router.url;
    }

    async getCompanyList() {
        (await this.organization.getAllUsersCompany(this.userId)).subscribe(
            async (response: any) => {
                this.companyList = [];
                if (response) {
                    this.companyCount = response?.length;
                    this.companyList = response?.map((company: any) => {
                        return {
                            id: company?.id,
                            company_name: company?.company_name,
                            profile_image: company?.profile_image,
                            member_count: company?.member_count,
                        };
                    });
                }
            }
        );
    }

    getImage(navbarItem: any) {
        switch (navbarItem) {
            case 'Dashboard':
                return `${this.imgFolderPathCommon}/dashboard-icon.svg`;
            case 'Tasks':
                return `${this.imgFolderPathCommon}/taskicon.svg`;
            case 'Projects':
                return `${this.imgFolderPathCommon}/projectsicon.svg`;
            case 'Discussion':
                return `${this.imgFolderPathCommon}/dicussionicon.svg`;
            case 'Documents':
                return `${this.imgFolderPathCommon}/documenticon.svg`;
            case 'Time sheet':
                return `${this.imgFolderPathCommon}/timesheeticon.svg`;
            case 'Notes':
                return `${this.imgFolderPathCommon}/notesicon.svg`;
            case 'Reports':
                return `${this.imgFolderPathCommon}/reportsicon.svg`;
            case 'Attendance (Beta)':
            case 'Attendance':
            return `${this.imgFolderPathCommon}/attendance-icon.svg`;
            case 'Leave (Beta)':
            case 'Leave':
                return `${this.imgFolderPathCommon}/leave-icon.svg`;
            case 'Settings':
                return `${this.imgFolderPathCommon}/settingsicon.svg`;
            case 'Users':
                return `${this.imgFolderPathCommon}/users.svg`;
            case 'Logout':
                return `${this.imgFolderPathCommon}/logouticon.svg`;
        }
    }

    async getTimeSheetDetail(company_id: any) {
        (
            await this.timeSheetSettingService.getTimesheetMasterSetting(company_id)
        ).subscribe((res: any) => {
            if (res) {
                this.timeLogData = res.result;
                this.store
                    .select('taskState')
                    .pipe(take(1))
                    .subscribe((ele) => {
                        const old = JSON.parse(JSON.stringify(ele.taskState));
                        old['timesheetConfig'] = res?.result;
                        this.store.dispatch(taskState({ data: old }));
                    });
            }
        });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async updateStoreData() {
        (await this.reportService.filterReport()).subscribe(async (res: any) => {
            const userKeyByValue = _.keyBy(res.result, 'id');
            res.result?.group_details.map((ele: any) => userKeyByValue[ele]);
            this.store
                .select('taskState')
                .pipe(take(1))
                .subscribe((ele) => {
                    const old = JSON.parse(JSON.stringify(ele.taskState));
                    old['reportConfig'] = res?.result;
                    this.store.dispatch(taskState({ data: old }));
                });
        });
    }

    checkMember() {
        if (this.selectedCompanyId) {
            try {
                const selectedCompany = CryptoJS.AES.decrypt(
                    this.selectedCompanyId?.toString().trim(),
                    ''
                ).toString(CryptoJS.enc.Utf8);
                this.companyList.forEach((member: any) => {
                    if (selectedCompany == member.id) {
                        this.memberCount = member.member_count;
                    }
                });
                return this.memberCount;
            } catch (error) { }
        }
    }

    changeComapny() {
        this.emailService.changeCompany$.subscribe((company: any) => {
            this.selectedValue(company, true);
        });
    }

    async companyswitch(id: any) {
        if (id) {
            const companyDetails = await this.companyList.find(
                (item: any) => item?.id == id
            );
            if (companyDetails) {
                this.authService
                    .updateToken({ company_id: id, user_id: this.userId })
                    .subscribe(async (response: any) => {
                        this.localStorage.setLocalStore('authorization', response.jwtToken);
                        this.selectedCompanyId = companyDetails?.id;
                        this.profile_image = companyDetails?.profile_image;
                        this.selectedCompanyId = CryptoJS.AES.encrypt(
                            this.selectedCompanyId?.toString().trim(),
                            ''
                        ).toString();
                        this.selectedCompany = companyDetails.company_name;
                        const selectedCompanyData = await companyDetails;
                        if (this.companyCount == 1 && companyDetails == undefined) {
                            this.authService
                                .updateToken({
                                    company_id: this.companyList[0]?.id,
                                    user_id: this.userId,
                                })
                                .subscribe(async (response: any) => {
                                    if (response) {
                                        await this.localStorage.setLocalStore(
                                            'authorization',
                                            response.jwtToken
                                        );
                                        const authorization = await this.localStorage.getLocalStore(
                                            'authorization'
                                        );
                                        const token = await this.localStorage.decodeJWT(
                                            authorization
                                        );
                                        const selectedCompany = token?.company_id;
                                        const companyDetails = this.companyList.find(
                                            (item: any) => item?.id == selectedCompany
                                        );
                                        this.selectedCompanyId = companyDetails?.id;
                                        const user_company_relations =
                                            await this.userDetail.getUserRolePermission(
                                                this.userId,
                                                this.selectedCompanyId
                                            );
                                        if (user_company_relations) {
                                            user_company_relations.member_id =
                                                user_company_relations?.memberId;
                                        }
                                        delete user_company_relations?.memberId;
                                        this.selectedCompany = selectedCompanyData?.company_name;
                                        this.avatar = await this.utils.forShortName(
                                            this.selectedCompany
                                        );
                                        debugger;
                                        await this.localStorage.setDataInIndexedDB(
                                            'selectedCompanyId',
                                            this.selectedCompanyId
                                        );
                                        // await this.localStorage.setDataInIndexedDB(
                                        //   "companyId",
                                        //   this.selectedCompanyId
                                        // );
                                        await this.localStorage.setDataInIndexedDB(
                                            'selectedCompanyName',
                                            selectedCompanyData?.company_name
                                        );
                                        await this.localStorage.setDataInIndexedDB(
                                            'selectedCompanyData',
                                            selectedCompanyData
                                        );
                                        location.reload();
                                    }
                                });
                        }
                        this.selectedCompany = selectedCompanyData?.company_name;
                        this.avatar = await this.utils.forShortName(this.selectedCompany);
                        debugger;
                        await this.localStorage.setDataInIndexedDB(
                            'selectedCompanyId',
                            this.selectedCompanyId
                        );
                        // await this.localStorage.setDataInIndexedDB(
                        //   "companyId",
                        //   this.selectedCompanyId
                        // );
                        await this.localStorage.setDataInIndexedDB(
                            'selectedCompanyName',
                            selectedCompanyData?.company_name
                        );
                        await this.localStorage.setDataInIndexedDB(
                            'selectedCompanyData',
                            selectedCompanyData
                        );
                    });
                this.getTimeSheetDetail(id);
            } else {
                this.localStorage.clearStorageFor('task_id');
                this.router.navigate(['/dashboard']).then(() => {
                    this.localStorage.clearStorageFor('task_id');
                    this.alertservice.showError('You are unauthorized for this company');
                });
            }
        }
    }
}
